.app {
    width: 100%;
}

.new-button {
    margin-left: 10px;
    width: 300px;
}

.app-modal.large {
    width: 90%;
    max-width: none !important;
}
